<template lang="pug">
  div
    loading(:active.sync="busy", :is-full-page="true")
    .widget(v-if="USER")
      .widget-header
        h1.title Advertisers
        i.la.la-2x.la-filter.d-flex.align-items-center.mr-2.mobile(@click="areFiltersOpened = !areFiltersOpened")
      .widget-body
        filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'advertisersList'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
          .form-row-main
            .form-group
              label Search
              input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
            .form-group
              label Status
              select.form-control(v-model="filters.status")
                option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
            .form-group.no-label
              b-button-group.submit-filters-button
                b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
                  b-dropdown-item(@click="loadData(1)")
                    i.la.la-download
                    | Export

        .form-group
          label &nbsp;
          router-link.btn.btn-success(:to="{name:'advertiser-add'}", v-if="USER.permissions['advertisers::ADD']")
            i.la.la-plus
            | Add

        p.clearfix &nbsp;

        .ov-table-wrapper
          table.table.table-bordered
            thead
              tr
                th ID
                th.text-center G
                th Active
                th Name
                th API
                th Last Fetch
                th Num. Offers
                th Secret
                th User
                th Pass
                th Panel
                th Joined
                th Actions
            tbody
              tr(v-if="records.length===0")
                td(colspan="13") No matching records were found
              tr(v-for="r in records", v-bind:key="r.id")
                td {{ r.id }}
                td.text-center {{ r.group_name }}
                td
                  toggle-button(:disabled="!USER.permissions.admin_only || !!r.reserved", v-model="r._status", :font-size="12", @change="updateField(r, 'status')")
                  //span(v-if="!USER.permissions.admin_only") {{ r.status ?  }}
                td.edit-inline
                  quick-edit(v-if="USER.permissions.admin_only", field="name", :r="r", @on-save="updateField", :wrapped="true")
                  span(v-if="!USER.permissions.admin_only") {{ r.name }}
                td
                  toggle-button(:disabled="!USER.permissions.admin_only || !!r.reserved", v-model="r._fetch_api", :font-size="12", @change="updateField(r, 'fetch_api')")
                  div {{ integrationHash[r.integration_type] }}
                td.has-actions
                  i.la.la-warning.mr-2(v-if="r.fetch_api_status === 2", v-b-tooltip.hover.left, title="API Error", :style="{color: '#dc3545'}")
                  span {{ r.last_fetch_api }}
                  a.cell-action(v-if="!r.reserved", href="javascript:void(0)", @click="fetchApi(r)", v-b-tooltip.hover, title="Fetch now")
                    i.la.la-refresh(:class="{'la-spin':r.api_processing}")
                td {{ r.api_num_offers }}
                td.has-actions {{ r.secret }}
                  a.cell-action(v-if="(resetSecret || (!r.secret && newSecret)) && !r.reserved", href="javascript:void(0)", @click="generateSecret(r)", v-b-tooltip.hover, title="Generate Secret")
                    i.la.la-refresh
                td.edit-inline
                  quick-edit(field="panel_username", :r="r", @on-save="updateField(r,'panel_username')", :wrapped="true")
                td.edit-inline
                  quick-edit(field="panel_password", :r="r", @on-save="updateField(r,'panel_password')", :wrapped="true")
                td.edit-inline.actions
                  a.btn.btn-default(:href="r.panel_url", target="_blank", v-if="r.panel_url")
                    i.la.la-fw.la-external-link
                td {{ r.created_at }}
                td.actions
                  .action-buttons(v-if="!r.reserved")
                    router-link.btn.btn-sm.btn-secondary(v-if="USER.permissions['advertisers::EDIT']", :to="{name:'advertiser-edit', params: {id:r.id}}")
                      i.la.la-pencil
                    | &nbsp;
                    a.btn.btn-sm.btn-secondary(v-if="USER.permissions.admin_only", href="")
                      i.la.la-trash
          paginate(:paginator="paginate", @update-page-size="loadData")


</template>
<script>
import Vue from 'vue';

export default {
  name: 'AdvertiserList',
  computed: {
    resetSecret() {
      return this.USER.permissions.admin_only || this.USER.permissions['advertisers::RESET_SECRET'];
    },
    newSecret() {
      return this.USER.permissions.admin_only || this.USER.permissions['advertisers::NEW_SECRET'];
    },
    USER() {
      return this.$store.state.user;
    },
  },
  data() {
    const defaultFilters = {
      keyword: '',
      status: 1,
    };
    return {
      areFiltersOpened: false,
      busy: false,
      // PERMS: null,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },
      defaultFilters,
      filters: { ...defaultFilters },
      statusOptions: [
        { v: undefined, t: 'All' },
        { v: 0, t: 'Inactive' },
        { v: 1, t: 'Active Only' },
      ],
      records: [],
      integrationHash: {},
    };
  },
  // mounted(){
  // 	let user = this.$store.state.user;
  // 	this.currentUser = user;
  // 	this.PERMS = user.permissions;
  // },
  methods: {
    async updateField(r, field) {
      let v = r['_' + field];
      let data = {
        id: r.id,
        key: field,
        val: v,
      };
      try {
        let resp = await this.$ovReq.post('advertiser/updateField', data);
        r['_edit_' + field] = false;
        r[field] = resp.entity[field];
        this.$ovNotify.success('Record has been updated');
      } catch (e) {
        this.$ovNotify.error(e);
      }
    },
    async generateSecret(r) {
      let confirmed = await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to generate a new secret?',
      );
      if (confirmed) {
        this.busy = true;
        let params = {
          id: r.id,
        };
        try {
          let resp = await this.$ovReq.post('advertiser/generateSecret', params);
          r.secret = resp.secret;
          this.$ovNotify.success('New secret generated');
        } catch (e) {
          this.$ovNotify.error(e);
        }
        this.busy = false;
      }
    },
    async fetchApi(r) {
      if (r._busy_fetch_api) {
        return;
      }
      let data = {
        id: r.id,
      };
      r._busy_fetch_api = true;
      try {
        await this.$ovReq.post('advertiser/fetchApi', data);
        r.api_processing = true;
        // r.last_fetch_api = resp.entity.last_fetch_api;
        // r.api_num_offers = resp.entity.api_num_offers;
        // this.$ovNotify.success('Got ' + resp.entity.api_num_offers + ' offers');
      } catch (e) {
        console.error(e);
      }
      r._busy_fetch_api = false;
    },
    async loadData(doExport) {
      this.busy = true;
      let params = {
        export: doExport ? doExport : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        keyword: this.filters.keyword,
      };
      if (this.filters.status === 0) {
        params.status = 0;
      } else if (this.filters.status === 1) {
        params.status = 1;
      }
      try {
        let resp = await this.$ovReq.get('advertiser/getList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.records = resp.records;
          this.records.forEach((r) => {
            Vue.set(r, '_busy_fetch_api', false);
            r.status = !!r.status;
            r.fetch_api = !!r.fetch_api;
            Vue.set(r, '_edit_name', false);
            Vue.set(r, '_name', r.name);

            Vue.set(r, '_status', r.status);
            Vue.set(r, '_fetch_api', r.fetch_api);
          });
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
    async loadMetaData() {
      let integrations = await Vue.ovData.general.getIntegrations();
      integrations.forEach((o) => {
        this.integrationHash[o.v] = o.t;
      });
    },
  },

  created() {
    this.loadMetaData();
    this.loadData();
  },
};
</script>
